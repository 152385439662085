<template>
  <div v-if="isDataLoaded">
    <!-- Controllers -->
    <div class="d-flex justify-end py-4 pr-6">
      <div class="mr-auto pl-6">
        <div class="d-flex flex-row align-center pl-16">
          <v-icon
            color="primary"
            size="16"
          >
            arrow_back
          </v-icon>
          <a @click="$router.push({ name: 'posts' })">
            <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span><span>{{ $t('project.sections.posts') }}</span>
          </a>
        </div>
      </div>
      <v-btn
        v-if="!isPublished"
        color="primary"
        type="submit"
        outlined
        depressed
        class="mr-6"
        @click.prevent="update({ publish: false })"
        v-text="$t('common.saveAsDraft')"
      />
      <v-btn
        color="primary"
        type="submit"
        dark
        depressed
        @click.prevent="update({ publish: true })"
        v-text="$t('common.publish')"
      />
    </div>
    <!-- Body -->
    <v-container class="px-9 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <v-row v-if="!isGuild" class="mb-8">
            <v-col cols="11">
              <h3 class="mb-4" v-text="$t('common.target')" />
              <span v-text="$t('project.academy.posts.targetSubtitle')" />
              <div id="teams">
                <v-combobox
                  v-model="form.teams"
                  v-on-clickaway="closeMenu"
                  :items="teams"
                  item-text="fullTeamName"
                  outlined
                  dense
                  multiple
                  hide-details="auto"
                  :label="$t('project.academy.posts.teamsFilter')"
                  append-icon="mdi-chevron-down"
                  :error-messages="getFieldErrors('teams')"
                  class="mt-4"
                  color="primary"
                  :menu-props="{ closeOnContentClick: true }"
                  @change="isMenuOpen = true"
                  @mousedown="isMenuOpen = true"
                  @blur="$v.form.teams.$touch()"
                >
                  <template #selection="{ item, index }">
                    <!-- open dropdown -->
                    <v-chip
                      v-if="form.teams.length && isMenuOpen"
                      close
                      color="primary"
                      outlined
                      @click:close="form.teams = form.teams.filter(team => team !== item)"
                    >
                      <span>
                        {{ labelText(item) }}
                      </span>
                    </v-chip>
                    <!-- closed dropdown -->
                    <span
                      v-else-if="index < 2 && !isMenuOpen"
                      class="text-truncate mr-1"
                      style="max-width: 15rem;"
                    >
                      {{ (index === 1 ? ', ' : '') + labelText(item) }}
                    </span>
                    <span
                      v-else-if="index === 2 && !isMenuOpen"
                      class="grey--text text-caption"
                    >
                      (+{{ form.teams.length - 2 }})
                    </span>
                  </template>
                </v-combobox>
              </div>
              <!-- <v-checkbox
                v-model="form.parentsAndCoachesOnly"
                :label="$t('project.academy.posts.parentsAndCoachesOnly')"
                hide-details="auto"
                class="mb-6"
              /> -->
              <!-- OW-3537
              <div id="roles">
                <v-combobox
                  v-model="form.roles"
                  v-on-clickaway="closeRolesMenu"
                  :items="roles"
                  item-text="name"
                  outlined
                  dense
                  multiple
                  hide-details="auto"
                  :label="$t('common.roles')"
                  append-icon="mdi-chevron-down"
                  :error-messages="getFieldErrors('roles')"
                  class="mt-4"
                  :menu-props="{ closeOnContentClick: true }"
                  @change="isRolesMenuOpen = true"
                  @mousedown="isRolesMenuOpen = true"
                  @blur="$v.form.roles.$touch()"
                >
                  <template #selection="{ item, index }">
                    <!-- open dropdown -->
              <!-- <v-chip
                      v-if="form.roles.length && isRolesMenuOpen"
                      close
                      color="primary"
                      outlined
                      @click:close="form.roles = form.roles.filter(role => role !== item)"
                    >
                      <span>
                        {{ labelRolesText(item) }}
                      </span>
                    </v-chip> -->
              <!-- closed dropdown -->
              <!-- <span
                      v-else-if="index < 3 && !isRolesMenuOpen"
                      class="text-truncate mr-1"
                      style="max-width: 15rem;"
                    >
                      {{ ([1, 2].includes(index) ? ', ' : '') + labelRolesText(item) }}
                    </span>
                    <span
                      v-else-if="index === 3 && !isRolesMenuOpen"
                      class="grey--text text-caption"
                    >
                      (+{{ form.roles.length - 3 }})
                    </span>
                  </template>
                </v-combobox>
              </div> -->
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col cols="11">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="$t('project.academy.posts.contentTitle')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '16:9',
                    width: '640px',
                    height: '360px'
                  })"
                />
              </div>
              <image-field v-model="form.banner" class="my-2" :width="640" :height="360" can-be-deleted suffix="_banner" @input="$v.form.banner.$touch()" />
              <div id="title">
                <v-text-field
                  v-model="form.title"
                  :error-messages="getFieldErrors('title')"
                  :label="$t('common.title')"
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-4"
                  @blur="$v.form.title.$touch()"
                />
              </div>
              <div id="text">
                <html-field
                  v-model="form.text"
                  :error-messages="getFieldErrors('text')"
                  :placeholder="$t('common.text')"
                  class="mt-4"
                  @blur="$v.form.text.$touch()"
                />
              </div>
              <files-field
                v-model="form.attachments"
                class="mt-4"
                @input="$v.form.attachments.$touch()"
                @delete="$v.form.attachments.$touch()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import { required, requiredIf } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'EditPost',
  components: {
    HtmlField: () => import('@/components/formFields/HtmlField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    FilesField: () => import('@/components/formFields/FilesField'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils, clickaway, saveDialogUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    postId: { type: String, default: null },
  },
  data() {
    return {
      isDataLoaded: false,
      form: {},
      isMenuOpen: false,
      isRolesMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ post: 'post/data' }),
    ...mapGetters('project', ['isGuild']),
    isPublished: ({ post }) => post?.published,
    isNewPost: ({ postId }) => !postId,
    roles() {
      // TODO: add Manager + DOC (director of coaching) when added to ddbb models
      return [
        {
          id: 'coach',
          name: this.$t('user.roles.coaches'),
        },
        {
          id: 'player',
          name: this.$t('user.roles.players'),
        },
        {
          id: 'parent',
          name: this.$t('user.roles.parents'),
        },
        {
          id: 'manager',
          name: this.$t('user.roles.managers'),
        },
        {
          id: 'submanager',
          name: this.$t('user.roles.submanagers'),
        },
      ]
    },
  },
  validations() {
    return {
      form: {
        teams: { required: requiredIf(() => !this.isGuild) }, // TODO: delete this line and uncomment two below to re-activate roles filter, OW-3537
        // teams: { required: requiredIf(() => !this.form.roles.length) },
        // roles: { required: requiredIf(() => !this.form.teams.length) },
        title: { required },
        text: { required },
        banner: {},
        attachments: {},
        parentsAndCoachesOnly: {},
      },
    }
  },
  async created() {
    const { organizationId, projectId, postId, parsePost, isNewPost } = this
    this.$store.commit('loader/show')

    if (postId) await this.$store.dispatch('post/bind', { organizationId, projectId, postId })
    if (isNewPost) await this.$store.dispatch('post/unbind', { organizationId, projectId, postId })

    this.form = postId
      ? await parsePost()
      : {
        teams: [],
        // roles: [], TODO: uncomment in the future, OW-3537
        title: '',
        text: '',
        banner: null,
        attachments: [],
        parentsAndCoachesOnly: false,
      }

    this.$store.commit('loader/hide')
    this.isDataLoaded = true
    window.scrollTo(0, 0)
  },
  methods: {
    async parsePost() {
      const { post, teams, roles } = this
      const { title, banner, text, attachments, userSources } = post
      return {
        teams: !this.isGuild ? teams?.filter(team => userSources?.teamIds?.includes(team.id)) : [],
        // roles: roles?.filter(role => userSources?.roles?.includes(role.id)), // TODO: OW-3537
        title,
        banner,
        text,
        attachments,
        parentsAndCoachesOnly: userSources?.excludeTeamPlayers ?? false,
      }
    },
    parseForm() {
      const { form, post } = this
      return (({ teams, roles, parentsAndCoachesOnly, ...data }) => ({
        ...post,
        ...data,
        ...(!this.isGuild && {
          userSources: {
            teamIds: teams.map(({ id }) => id),
            // roles: roles.map(({ id }) => id), // TODO: OW-3537
            excludeTeamPlayers: parentsAndCoachesOnly,
            allIds: teams
              .flatMap(team => parentsAndCoachesOnly ? team.id : [team.id, `player-${team.id}`]),
          // .concat(roles.map(role => role.id)), // TODO: OW-3537 uncomment
          },
        }),
      }))(form)
    },
    async update({ publish }) {
      const { organizationId, projectId, parseForm, isNewPost, post } = this
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      this.runAsync(async () => {
        if (isNewPost) {
          await this.$store.dispatch('post/create', { organizationId, projectId, data: parseForm(), publish })
        } else {
          await this.$store.dispatch('post/update', { organizationId, projectId, post, data: parseForm(), publish })
        }
      })
      this.userAgreedToLeave = true
      this.$router.push({ name: 'posts' })
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    closeRolesMenu() {
      this.isRolesMenuOpen = false
    },
    labelText(item) {
      return this.teams.find(team => team.id === item.id)?.fullTeamName
    },
    labelRolesText(item) {
      return this.roles.find(role => role.id === item || role.id === item?.id)?.name
    },
  },
}
</script>
